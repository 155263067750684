import React from "react"
import { Link, graphql } from "gatsby"
import FullStory from 'react-fullstory';
import { navigate } from "gatsby"

import ReactTooltip from 'react-tooltip';
import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid'
import { InformationCircleIcon } from '@heroicons/react/outline'

import withLocation from "../components/common/withlocation"
import SponsorLink from "../components/promotions/sponsorLink"
import Navigation from "../components/common/navigation"

import Layout from "../components/common/layout"
import Header from "../components/common/header"
import Footer from "../components/common/footer"
import SEO from "../components/common/seo"
import Image from "../components/common/image"
import SubscriptionForm from "../components/common/subscribe"
import TopicList from "../components/topic/topiclist"
import CommunitiesTable from "../components/community/communitiestable"
import PlatformCard from "../components/platform/platformcard"
import FeatureList from "../components/feature/featurelist"
import ScrollingCommunities from "../components/community/scrollingCommunities"

import { sortCommunities } from '../utils/communities'

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'



const IndexPage = ({data, location}) => {

  const isMobile = (typeof window !== 'undefined') && window.screen.width < 600;
  const platformsToDisplay = data.platforms.nodes.sort((a,b) => (a.promoted < b.promoted) ? 1 : ((b.promoted < a.promoted) ? -1 : 0)).filter(p => p.slug !== 'independent').slice(0, 6);

  const navigation = [
    // { name: 'About', href: '/about/' },
    { name: 'Topics', href: '/topics/' },
    { name: 'Communities', href: '/communities/' },
    // { name: 'Blog', href: '/blog/' },
    { name: 'Add Community', href: '/submit/', button: false },
    { name: 'Advertise', href: '/partners/community/' },
    { name: 'More', nested: true, links: [
      { name: 'Platforms', href: '/platforms/' },
      { name: 'Features', href: '/features/' },
      { name: 'Tools', href: '/tools/' },
      { name: 'Traffic', href: '/partners/traffic/' },
    ]},
    // { name: 'Add Community', href: '/submit/', button: false },
  ]


  return (

    <div className="bg-gray-50">
      <SEO title="Find The Best Online Communities | Hive Index" description="Hive Index is a directory of online communities, organized by topic, platform, and community features."
        schema={{
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Hive Index",
          "url": "https://thehiveindex.com",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://thehiveindex.com/search/?keyword={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }}

      />
      {/*<FullStory org={'14XCBT'} />*/}

      <div className="bg-yellow-300">
        <div className="overflow-hidden lg:relative text-black">
          
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-transparent">
              <div className="px-2 flex items-center lg:px-0">
                <Link to="/" className="flex-shrink-0">
                  <Image className="block h-10 w-auto" fileName="icon.png" alt="Hive Index Logo" height="35" width="31"/> 
                </Link>

                <div className="ml-6 hidden sm:flex">
                  <Navigation navigation={navigation} />
                </div>
              </div>
            </div>


          </div>

          {/*<div className="h-16 flex items-center mx-auto mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <Image className="block h-10 w-auto" fileName="icon.png" alt="Hive Index Logo"/> 
          </div>*/}
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>
              <div className="mt-20">
                

                <div className="mt-6 sm:max-w-xl">
                  <div className="sm:max-w-lg">
                    <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl">
                      Discover the best <span className="border-b-4 border-rose-500">online communities</span>
                    </h1>
                  </div>
                  {/*<h2 className="mt-6 text-xl font-semibold opacity-80">
                    Hive Index helps you find online communities perfect for you
                  </h2>*/}
                  <h2 className="mt-6 text-xl font-semibold opacity-80">
                    Curated lists of the top online communities to join
                  </h2>
                </div>

                <form 
                  onSubmit={(event) => {
                    event.preventDefault();
                    const value = document.getElementById('searchfield').value;
                    if (value && value !== ""){
                      navigate(`/search/?keyword=${encodeURIComponent(value)}`)
                    }
                  }}
                  className="mt-8 sm:max-w-lg sm:w-full sm:flex mb-4">

                  <div className="min-w-0 flex-1">
                    <label htmlFor="searchfield" className="sr-only">
                      Search topics & communities
                    </label>
                    <input
                      id="searchfield"
                      name="search"
                      className="block w-full border border-transparent shadow rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                      placeholder={`Search ${data.communities.totalCount} online communities`}
                      type="search"
                      autoComplete="off"
                      spellCheck="off"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <button
                      type="submit"
                      className="block w-full rounded-md border border-transparent px-5 py-3 bg-rose-600 text-base font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
                    >
                      Go →
                    </button>
                  </div>
                </form>

                <div className="mt-8 mb-8">
                  <Link to="/submit/" className="inline-flex space-x-4 opacity-80 hover:opacity-100">
                    <span className="rounded bg-rose-600 text-white px-2.5 py-1 text-xs font-semibold tracking-wide uppercase">
                      Building a community?
                    </span>
                    <span className="inline-flex text-black items-center text-sm font-medium space-x-1">
                      <span>List it for free</span>
                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </Link>
                </div>

                <div className="hidden sm:flex">
                  <a href="https://www.producthunt.com/posts/hive-index?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-hive-index" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=321612&theme=light" alt="Hive Index - Find the best communities on the internet | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" />
                  </a>

                  {/*<a className="ml-2" href="https://www.producthunt.com/posts/hive-index?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-hive-index" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=321612&theme=light&period=daily" alt="Hive Index - Find the best communities on the internet | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" />
                  </a>*/}
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:py-20">
            <div className="hidden sm:block sm:relative lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="hidden sm:block">
                <div className="absolute inset-y-0 left-1/2 w-screen bg-yellow-400 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                <svg
                  className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-yellow-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                </svg>
              </div>
              <div className="hidden lg:block relative sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none h-full sm:pl-12">
                {!isMobile ? (
                  <ScrollingCommunities communities={data.featuredCommunities.nodes}/>
                ) : ''}
              </div>
              {/*<div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                <img
                  className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://tailwindui.com/img/component-images/top-nav-with-multi-column-layout-screenshot.jpg"
                  alt=""
                />
              </div>*/}
            </div>
          </div>
        </div>
      </div>


      <main className="relative py-16">
        <div className="max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">

          <section className="mb-12">
            <div className="max-w-4xl mx-auto bg-white rounded-lg shadow">
              <div className="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
                <div className="text-left">
                  <div className="text-center">
                    <p className="text-base font-semibold text-rose-500 tracking-wide uppercase">The Hive Index</p>
                    <h2 className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-4xl">
                      A directory of online communities
                    </h2>
                  </div>
                  <div className="max-w-xl mt-5 mx-auto text-lg text-gray-500">
                    <p className="pb-2">We believe that all who want to surround themselves with community should be able to do so.</p>
                    <p className="pb-2">This website is a free resource for professionals, creatives, students, teachers, entrepreneurs, and those that are just looking for some likeminded souls to hang out with.</p>
                    <p className="pb-2">With your help, this list of communities & topics can keep growing. If you know of a good community that's not listed, submit it. If you'd like a new topic curated, let us know.</p>
                    <p className="pb-2">Thanks, and welcome to the Hive Index.</p>
                  </div>
                </div>
              </div>

              <div className="p-8 pt-0">
                <hr className="text-gray-50" />
              </div>

              <dl className="rounded-lg mx-auto grid grid-cols-2 sm:grid-cols-4">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0">
                  <dt className="order-2 mt-2 text-base leading-6 font-medium text-gray-500">
                    <Link to="/topics/" className="hover:underline">Topics</Link>
                  </dt>
                  <dd className="order-1 text-xl sm:text-3xl font-extrabold text-rose-500">{data.topics.totalCount}</dd>
                </div>
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-base leading-6 font-medium text-gray-500">
                    <Link to="/communities/" className="hover:underline">Communities</Link>
                  </dt>
                  <dd className="order-1 text-xl sm:text-3xl font-extrabold text-rose-500">{data.communities.totalCount}</dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-base leading-6 font-medium text-gray-500">
                    <Link to="/platforms/" className="hover:underline">Platforms</Link>
                  </dt>
                  <dd className="order-1 text-xl sm:text-3xl font-extrabold text-rose-500">{data.platforms.totalCount}</dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-base leading-6 font-medium text-gray-500">Last Updated</dt>
                  <dd className="order-1 text-xl sm:text-3xl font-extrabold text-rose-500">{data.communities.nodes[0].updated}</dd>
                </div>
              </dl>
            </div>

            {/* Optional sponsorship link. Only shows if active sponsor for the site */}
            <SponsorLink location={'home'} canHide={false}>
              {({ name, url, descriptor, onClick}) => (
                <div className="max-w-4xl mt-8 mx-auto p-2 px-3 mb-6 rounded-md bg-rose-500 text-white font-semibold flex items-center">
                  <div>Brought to you by <a href={url} className="underline" onClick={onClick} target="_blank" rel="sponsored nofollow">
                    {name} - {descriptor}</a>
                  </div>
                </div>
              )}
            </SponsorLink>

          </section>

          <section>
            <div className="mt-2 py-16 bg-gray-50 overflow-hidden lg:py-24">
              <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <svg
                  className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                </svg>

                <div className="relative">
                  <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    How the Hive Index works
                  </h3>
                </div>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-3 lg:gap-12">
                  <div className="relative">
                    <h3 className="sm:mt-6 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                      Communities are organized by <Link to="/topics/" className="border-b-2 border-rose-500 hover:text-rose-500">
                        Topics
                      </Link>
                    </h3>
                    <p className="mt-3 text-lg text-gray-500">
                      Be it professional, creative, or just for fun, our topic lists range far and wide.
                    </p>
                    <p className="mt-3 text-lg text-gray-500">
                      Browse the existing topics, or <Link to="/suggest/" className="underline">suggest</Link> a new one if you feel like we are missing something.
                    </p>
                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 col-span-2 bg-white rounded-lg shadow overflow-hidden p-4 sm:p-8" aria-hidden="true">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-200" />
                      </div>
                      <div className="relative flex items-center justify-between">
                        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Popular Topics</h2>
                      </div>
                    </div>

                    <div className="mt-2 text-gray-500">
                      <TopicList topics={data.topics.nodes.filter((n, i) => !isMobile ? true : i <= 3 )} mini={true} cols={3} />
                      
                      <div className="p-6 pb-0 mx-auto text-center">
                        <div className="mx-auto text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer">
                          <Link to="/topics/">Browse All {data.topics.totalCount} Topics →</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <svg
                  className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={400} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg>

                <div className="relative mt-12 sm:mt-16 lg:mt-24">
                  <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-3 lg:gap-12">
                    <div className="lg:col-start-3">
                      <h2 className="sm:mt-6 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Across various <Link to="/platforms/" className="border-b-2 border-rose-500 hover:text-rose-500">
                        Community Platforms
                      </Link></h2>
                      <p className="mt-3 text-lg text-gray-500">
                        Pick a platform that suits your communication style.
                      </p>
                      <p className="mt-3 text-lg text-gray-500">
                        Or for something more custom, explore our <Link to="/platforms/independent/" className="underline">
                          {data.platforms.nodes.filter(p => p.slug === 'independent')[0].count} independent communities
                        </Link>.
                      </p>  
                      {platformsToDisplay.filter(p => p.promoted).length ? (
                        <p className="mt-3 text-lg text-gray-500">
                          If you are building community, check out one of our recommended <Link to={`/platforms/${platformsToDisplay.filter(p => p.promoted)[0].slug}/`} className="underline">community platforms</Link>.
                        </p>  
                      ) : ''}                    
                    </div>

                    <div className="mt-10 -mx-4 relative lg:mt-0 g:col-start-1 col-span-2 bg-white rounded-lg shadow overflow-hidden p-8">
                      <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="w-full border-t border-gray-200" />
                        </div>
                        <div className="relative flex items-center justify-between">
                          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Platforms</h2>
                        </div>
                      </div>

                      <div className="mt-2 text-gray-500">
                        <ul className={`grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-3 xl:gap-x-6`}>
                          {platformsToDisplay.filter((n, i) => !isMobile ? true : i <= 2 ).map(p=>(
                            <PlatformCard key={p.slug} platform={p} highlightPromoted={true} />
                          ))}
                        </ul>
                        
                        <div className="p-6 pb-0 mx-auto text-center">
                          <div className="mx-auto text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer">
                            <Link to="/platforms/">Browse All {data.platforms.totalCount} Platforms →</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <svg
                  className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={630} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                </svg>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-3 lg:gap-12">
                  <div className="relative">
                    <h3 className="sm:mt-6 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                      Tagged with <Link to="/features/" className="border-b-2 border-rose-500 hover:text-rose-500">
                        Features
                      </Link>
                    </h3>
                    <p className="mt-3 text-lg text-gray-500">
                      The Hive Index shows metadata on each community with a set of common features, so that you can pick a community that fits exactly what you are looking for.
                    </p>
                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 col-span-2 bg-white rounded-lg shadow overflow-hidden p-8" aria-hidden="true">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-200" />
                      </div>
                      <div className="relative flex items-center justify-between">
                        <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
                          <div>All Features</div>
                        </h2>
                      </div>
                    </div>
                    <div className="mt-2">
                      <FeatureList features={data.features.nodes} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative mt-12 sm:mt-16 lg:mt-24">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-3 lg:gap-12">
                  <div className="lg:col-start-3">
                    <h3 className="sm:mt-6 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                      Growing every day
                    </h3>
                    <p className="mt-3 text-lg text-gray-500">
                      Why not stay a while? Drop your email and we'll keep you up to date with fresh new communities and community industry insights, delivered straight to your inbox.
                    </p>                    
                  </div>

                  <div className="relative mt-4 sm:mt-0 g:col-start-1 col-span-2 bg-white rounded-lg shadow overflow-hidden">
                    <SubscriptionForm hideTitle={true}/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-8">
            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Explore the best online communities
              </h2>
            </div>


            {data.sponsoredCommunities.nodes.length ? (
              <div className="p-4 sm:p-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex items-center justify-between">
                    <ReactTooltip id={`top-picks-tooltip`} place="right" effect="solid" backgroundColor="black" textColor="white"
                      className="w-60"
                    />
                    <div className="pr-3 bg-gray-50 text-xl font-medium text-gray-900 flex items-center">
                      <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                        </svg>
                        <span>Today's top picks</span>
                      </div>
                      <div data-for={`top-picks-tooltip`} data-tip={`These are some of our favorite communities listed in the directory. Select communities displayed here could be advertising partners.`}>
                        <InformationCircleIcon className="ml-2 h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
                      </div>
                    </div>
                    
                  </div>
                </div>

                <div className="mt-1">
                  <CommunitiesTable
                    communities={sortCommunities("Oldest", data.sponsoredCommunities.nodes)}
                    showPlatform={true} showTopics={true} linkModal={false}
                    showVisitors={true}
                  />
                </div>
              </div>
            ) : ''}
            

            <div className="mx-auto">
              <form onSubmit={(event) => {
                event.preventDefault();
                const value = document.getElementById('searchfield-bottom').value;
                if (value && value !== ""){
                  navigate(`/search/?keyword=${value}`)
                }
              }}
              className="mt-12 sm:max-w-lg sm:w-full sm:flex mx-auto"
            >
                <div className="min-w-0 flex-1">
                  <label htmlFor="searchfield" className="sr-only">
                    Search topics & communities
                  </label>
                  <input
                    id="searchfield-bottom"
                    name="search"
                    className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                    placeholder={`Search all ${data.communities.totalCount} online communities`}
                    type="search"
                    autoComplete="off"
                    spellCheck="off"
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="block w-full rounded-md border border-transparent px-5 py-3 bg-rose-600 text-base font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
                  >
                    Go →
                  </button>
                </div>
              </form>
              <div className="mb-6 text-gray-500">
                <div className="p-6 pb-0 mx-auto text-center">
                  <div className="mx-auto text-gray-600 font-semibold text-sm mt-2">
                    <Link to="/topics/" className="opacity-75 hover:opacity-100 cursor-pointer">Or browse all community topics →</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          
        </div>
      </main>
    

      <Footer />
    </div>
  )
}

export const query = graphql`
  query{
    topics: allGoogleSpreadsheetSourceTopics(limit: 12, sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
      totalCount
      nodes {
        name
        slug
        description
        count
        image
        people
      }
    }
    platforms: allGoogleSpreadsheetSourcePlatforms(sort: {fields: count, order: DESC}) {
      totalCount
      nodes {
        slug
        name
        count
        logoBig
        groupName
        groupNamePlural
        promoted
      }
    }
    features: allGoogleSpreadsheetSourceFeatures(sort: {fields: count, order: DESC}) {
      totalCount
      nodes {
        slug
        name
        order
        sentenceEnding
        count
        icon
        iconActive
        prefix
      }
    }
    communities: allGoogleSpreadsheetSourceCommunities(sort: {fields: order, order: DESC}, limit: 8, filter: {removed: {ne: true}}){
      totalCount
      nodes {
        name
        slug
        updated(formatString: "MM/DD/YY")
        members
        established
        description
        topics
        platform
        logo
        forum
        chat
        apply
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
      }
    }
    featuredCommunities: allGoogleSpreadsheetSourceCommunities(
      limit: 18,
      sort: {fields: reviewCount, order: DESC},
      filter: {logo: {ne: null}, paid: {eq: true}, removed: {ne: true}}
    ){
      totalCount
      nodes {
        name
        slug
        updated(formatString: "MM/DD/YY")
        members
        established
        description
        topics
        platform
        logo
        forum
        chat
        apply
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
        reviewCount
        reviewAvg
      }
    }
    sponsoredCommunities: allGoogleSpreadsheetSourceCommunities(limit: 20, filter: {sponsored: {eq: true}, removed: {ne: true}}){
      totalCount
      nodes {
        name
        slug
        updated(formatString: "MM/DD/YY")
        members
        established
        description
        topics
        platform
        logo
        forum
        chat
        apply
        pairing
        events
        perks
        jobs
        newsletter
        paid
        courses
        tools
        token
        reviewCount
        reviewAvg
        visitors
        views
        redirects
      }
    }
  }
`

export default withLocation(IndexPage)
